.Resume {
  aspect-ratio: 8.5 / 11;
}

@media print {
  html {
    visibility: hidden;
  }

  .Resume {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
  }

  @page { margin: 0; }
}